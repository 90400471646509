import { Link } from 'gatsby';
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useForm } from '@formspree/react';
import { MdEmail } from 'react-icons/md';
import Layout from '../components/layout/layout';

const Kontakt = () => {
    const [state, handleSubmit] = useForm('xvoedozw');
    if (state.succeeded) {
        return (
            <div className="w-full px-4 py-4 flex justify-center">
                <div className="bg-gray-100 w-full sm:w-3/4 lg:w-1/4 mt-24 rounded-t-lg shadow-xl px-10 py-4">
                    <h3 className="text-xl mb-4 font-bold text-black">
                        Dziękujemy za wiadomość!
                    </h3>
                    <p className="mb-4">
                        Postaramy się odpisać w ciągu 24h. Zawsze możesz napisać
                        do nas też na social media, tam odpisujemy najszybciej.{' '}
                    </p>
                    <Link to="/">
                        <button className="h-10 w-48 px-3 md:h-12 md:px-5 text-white bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                            Wróc do strony
                        </button>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <Layout
            title="Kontakt - WrapTown - profesjonalne oklejanie samochodów"
            desc="Skontaktuj się z nami dzwoniąc lub pisząc do nas. Umów się na usługę oklejenia samochodu: zmiana koloru auta w Kościanie."
            url="https://wraptown.pl/kontakt"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> / Kontakt
                    </p>
                </div>
                <div className="mb-10">
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Kontakt - WrapTown
                    </h1>
                    <p>
                        Skontaktuj się z nami poprzez formularz kontaktowy lub
                        zadzwoń!
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 mb-16 lg:mb-0">
                        <h3 className="text-2xl mb-4 font-bold text-black">
                            Dane kontaktowe
                        </h3>
                        <p>Tomasz Frąckowiak</p>
                        <p>WrapTown</p>
                        <p>64-000 Kościan, Zawadzkiego 40</p>
                        <p>602 135 913</p>
                        <p className="mb-4">kontakt@wraptown.com.pl</p>
                        <ul className="flex">
                            <li className="mr-4">
                                <Link
                                    to="https://www.facebook.com/wraptownpl"
                                    target="_blank"
                                >
                                    <FaFacebookF />
                                </Link>
                            </li>
                            <li className="mr-4">
                                <Link
                                    to="https://twitter.com/wraptownpl"
                                    target="_blank"
                                >
                                    <FaTwitter />
                                </Link>
                            </li>
                            <li className="mr-4">
                                <Link
                                    to="https://www.instagram.com/wraptownpl/"
                                    target="_blank"
                                >
                                    <FaInstagram />
                                </Link>
                            </li>
                            <li className="mr-4">
                                <a href="mailto:kontakt@wraptown.com.pl">
                                    <MdEmail />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <h3 className="text-2xl mb-4 font-bold text-black">
                            Napisz do nas
                        </h3>
                        <form
                            onSubmit={handleSubmit}
                            className="w-full sm:w-2/3 flex flex-col"
                        >
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Imię i nazwisko"
                                className="h-10 md:h-12 mb-4 shadow appearance-none border rounded w-full  px-3 md:px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />

                            <input
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="h-10 md:h-12 mb-4 shadow appearance-none border rounded w-full  px-3 md:px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <textarea
                                name="message"
                                id="message"
                                placeholder="Wiadomość..."
                                className="h-30 md:h-36 mb-4 shadow appearance-none border rounded w-full align-top py-4 px-3 md:px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <div>
                                <button
                                    type="submit"
                                    disabled={state.submitting}
                                    className="h-10 w-48 px-3 md:h-12 md:px-5 text-white bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline"
                                >
                                    Wyślij
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default Kontakt;
